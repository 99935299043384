import React, { Component } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import Spinner from "../layout/Spinner";
import img from "../../images/img.jpg";

class Issues extends Component {
  state = {
    hasMore: true,
    issues: [],
    start: null
  };

  async componentDidMount() {
    let url = `https://apis.abegs.org/abegsjournal/getjournals?start=0`;
    let data = await fetch(url);
    let json = await data.json();
    if(json.paging.moreResults === 'MORE_RESULTS_AFTER_LIMIT'){
        this.setState({ issues: json.data, paging: json.paging, start: json.paging.endCursor, hasMore: true});
    }
    else{
        this.setState({ issues: json.data, paging: json.paging, start: json.paging.endCursor, hasMore: false});
    }
  }

  fetchMoreData = async () => {
    let url = `https://apis.abegs.org/abegsjournal/getjournals?start=${this.state.start}`;
    let data = await fetch(url);
    let json = await data.json();
    if(json.paging.moreResults === 'MORE_RESULTS_AFTER_LIMIT'){
        setTimeout(() => {
            this.setState(state => ({
                issues: [
                    ...state.issues,
                    ...json.data
                ],
                start:json.paging.endCursor,
                hasMore: true
            }));
        }, 1500);
    }
    else{
        setTimeout(() => {
            this.setState(state => ({
                issues: [
                    ...state.issues,
                    ...json.data
                ],
                hasMore: false
            }));
        }, 1500);
        return
    }
  };

  render() {
    const { issues } = this.state;
    if (issues) {
      return (
        <div className="container">
            <InfiniteScroll dataLength={this.state.issues.length} next={this.fetchMoreData} hasMore={this.state.hasMore} loader={ <Spinner /> } endMessage={<p style={{textAlign: 'center'}}><b>تم عرض كل البيانات</b></p>}>
            <div className="row">
              {issues.map(issue => (
                <div className="col-12 col-sm-4" key={issue.id}>
                  <div className="card text-darck border-dark m-1"  >
                    <img src={img} alt={issue.subject} className="card-img" />
                    <Link to={`/issue/${issue.id}`} className="btn btn-sm">
                      <div className="card-img-overlay row align-items-center">
                        <h3 className="col card-text text-muted">
                          {issue.issue_number}
                        </h3>
                      </div>
                    </Link>
                    <div className="card-footer bg-dark border-top-0 p-1">
                      <small className="text-light">{issue.subject}</small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </InfiniteScroll>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

export default Issues;
