import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaClipboardList, FaTasks, FaAngleLeft, FaFileAlt, FaSearch, FaThList } from 'react-icons/fa';

class Home extends Component {
    state = {
    };

    async componentDidMount() {

    }

    render() {
        return (
            <div className="container">


                <div className="row">
                    <div className="col-12 col-sm-12">
                        <div className="card border-dark mb-3">
                            <div className="card-body text-dark">
                                <p className="card-text text-justify">
                                    مجلة فصلية محكمة تعني بالدراسات التربوية والثقافية العامة ، و قد صدر العدد الأول منها باسم "نشرة التوثيق التربوي لدول الخليج العربية" عام 1399هـ ، وصدر العدد الثاني عام 1400 هـ باسم " مجلة التوثيق التربوي" ، وفي عام 1401هـ، صدرت باسم "مجلة رسالة الخليج العربي" بدءا من العدد الرابع.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12 col-sm-4">
                        <div className="card text-white bg-secondary mb-3">
                            <div className="card-body">
                                <h5 className="card-title"><FaFileAlt size={100} /></h5>
                                <p className="text-justify">بإمكانكم استعراض كافة اعداد المجلة السابقة بكافة بحوثها منذ أول عدد للمجلة</p>
                                <Link to={`/issues`} className="m-1 btn btn-outline-light float-left"> استعراض <FaAngleLeft /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="card text-white bg-info mb-3">
                            <div className="card-body">
                                <h5 className="card-title"><FaSearch size={100} /></h5>
                                <p className="text-justify">للبحث في بحوث المجلة حسب العنوان أو الباحث ... يمكنكم ذلك من خلال هذه التفاصيل</p>
                                <Link to={`/researchs/`} className="m-1 btn btn-outline-light float-left"> بـحـث <FaAngleLeft /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="card text-white bg-dark mb-3">
                            <div className="card-body">
                                <h5 className="card-title"><FaThList size={100} /></h5>
                                <p className="text-justify"> لاستعراض ابحاث المجلة حسب الفئات ... من خلال هذه التفاصيل </p>
                                <Link to={`/categories/`} className="m-1 btn btn-outline-light float-left"> عـرض <FaAngleLeft /></Link>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="card border-secondary mb-3">
                            <div className="card-header">
                                أهداف المجلة
                        </div>
                            <ul className="list-group list-group-flush text-secondary text-left">
                                <li className="list-group-item"> إثراء ساحة الفكر التربوي بالجديد والمتميز من البحوث والدراسات.</li>
                                <li className="list-group-item"> إذكاء روح البحث التربوي داخل المنطقة وخارجها.</li>
                                <li className="list-group-item"> توفير رافد لنشر البحوث والأفكار التي تهم المتخصصين وتفيد صانعي القرار التربوي.</li>
                                <li className="list-group-item"> إحياء روح البحث في التراث التربوي الإسلامي. </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="card border-secondary mb-3">
                            <div className="card-header">
                                سياسة المجلة
                            </div>
                            <ul className="list-group list-group-flush text-secondary text-left">
                                <li className="list-group-item"> تعد الاعداد من خلال البحوث التي ترد إلى المجلة. وفقا لأهداف المكتب واهتماماته.</li>
                                <li className="list-group-item"> تحكم البحوث  من هيئة التحرير قبل إرسالها إلى المحكمين لتحديد مدى صلاحيتها.</li>
                                <li className="list-group-item"> يكلف بتحكيم كل بحث ٢ محكمين ويتم تكليف محكم ثالث مرجحًا عندما يتطلب الأمر.</li>
                                <li className="list-group-item"> يتم النشر في ضوء تقارير المحكمين بعد عرضها على هيئة التحرير.</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12 col-sm-4">
                        <div className="card text-white bg-success mb-3">
                            <div className="card-body">
                                <h5 className="card-title"><FaFileAlt size={100} /></h5>
                                <p className="text-justify">لمعرفة المزيد من التفاصيل حول المجلة يمكنكم ذلك من خلال </p>
                                <Link to={`/about/`} className="m-1 btn btn-outline-light float-left"> المزيد <FaAngleLeft /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="card text-dark bg-light mb-3">
                            <div className="card-body">
                                <h5 className="card-title"><FaTasks size={100} /></h5>
                                <p className="text-justify">لمعرفة شروط النشر في المجلة بإمكانكم استعراض الشروط كاملة من هنا</p>
                                <Link to={`/terms/`} className="m-1 btn btn-outline-dark float-left"> استعراض <FaAngleLeft /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="card text-white bg-primary mb-3">
                            <div className="card-body">
                                <h5 className="card-title"><FaClipboardList size={100} /></h5>
                                <p className="text-justify">لمعرفة مجالات النشر في رسالة الخليج العربي  يمكنكم مشاهدتها من هنا</p>
                                <Link to={`/domains/`} className="m-1 btn btn-outline-light float-left"> استعراض <FaAngleLeft /></Link>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

export default Home;
