import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Alert = props => {
  const { message, messageType } = props;
  return (
    <div className={classnames('alert', { 'alert-success': messageType === 'success', 'alert-danger': messageType === 'error' })} >
    <button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
      {message}
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired
};
Alert.defaultProps = {
  messageType: 'error'
}
export default Alert;
