import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import AppNavbar from "./components/layout/AppNavbar";
import Signin from "./components/auth/Signin";
import Signup from "./components/auth/Signup";
import Home from "./components/Home";
import Issues from "./components/issues/Issues";
import Researchs from "./components/issues/Researchs";
import Categories from "./components/issues/Categories";
import CategoryDetials from "./components/issues/CategoryDetials";
import IssuesDetails from "./components/issues/IssuesDetails";
import About from "./components/issues/About";
import Terms from "./components/issues/Terms";
import Domains from "./components/issues/Domains";
import Persons from "./components/persons/Persons";
import ResearchViewer from "./components/issues/ResearchViewer";
import logo from "./images/logo.jpg";

class App extends Component {

  state = { data: null, isAuthed: false, email: null };

  parentMethod = (data) => {
    let isAuthed = data.userId ? true : false;
    this.setState({ data, isAuthed, token: data.token, userId: data.userId, email: data.email });
  }

  parenClear = () => {
    this.setState({ data: null, isAuthed: false, email: null });
  }

  render() {
    return (
      <Router>
        <div className="App">
          <AppNavbar isAuthed={this.state.isAuthed} email={this.state.email} parenClear={this.parenClear} />
          <img src={logo} className="img-fluid pb-4" alt="..."/>
          <div className="container">
            <Switch>
              <Route exact path="/" render={props => (<Home {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />)} />
              <Route exact path="/issues" render={props => (<Issues {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />)} />
              <Route exact path="/issue/:id" component={IssuesDetails} />
              <Route exact path="/researchs" render={props => (<Researchs {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />)} />
              <Route exact path="/categories" render={props => (<Categories {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />)} />
              <Route exact path="/categories/:id" render={props => (<CategoryDetials {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />)} />
              <Route exact path="/research/:id" render={(props) => <ResearchViewer {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />} />
              <Route exact path="/about/" component={About} />
              <Route exact path="/terms/" component={Terms} />
              <Route exact path="/domains/" component={Domains} />
              <Route exact path="/signin" render={(props) => <Signin {...props} isAuthed={this.state.isAuthed} parentMethod={this.parentMethod} />} />
              <Route exact path="/signup" render={(props) => <Signup {...props} isAuthed={this.state.isAuthed} parentMethod={this.parentMethod} />} />
              <Route exact path="/persons" render={(props) => <Persons {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
export default App;
