import React, { Component } from 'react';
import Alert from '../layout/Alert';
import jwtDecode from 'jwt-decode'
import { FaUserAlt } from 'react-icons/fa';


class Signup extends Component {

    state = {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        message: null,
        messageType: null,
    };

    onSubmit = async e => {
        e.preventDefault();
        try {
            const { first_name, last_name, email, password } = this.state;
            let url = `https://apis.abegs.org/abegsjournal/signup`;
            let user = { first_name: first_name, last_name: last_name, email: email, password: password }
            let data = await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(user) });
            let json = await data.json();
            if (json.accessToken !== 'error'){
                this.setState({ token: json.accessToken });
                let userId = jwtDecode(this.state.token).userId;
                this.props.parentMethod({ userId, token: json.accessToken, email: this.state.email });
                this.props.history.push('/');    
            }
            else{
                this.setState({ token: null, message: "حصل هناك خطأ اثناء التسجيل ... الرجاء المحاولة مرة آخرى" });
            }
        }
        catch (err) {
            this.setState({ token: null, message: "حصل هناك خطأ اثناء التسجيل ..." + err });
        }
    }
    onChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        return (
            <div className="row">
                <div className="col-md-6 mx-auto">
                    <div className="card">
                        <div className="card-body">
                            {this.state.message ? (
                                <Alert message={this.state.message} messageType="error" />) : null}
                            <h4 className="text-center pb-4 pt-3">
                                <span className="text-primary">
                                    تسجيل الدخول
                </span>
                            </h4>
                            <form onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label htmlFor="first_name">الأسم الأول:	</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="first_name"
                                        required
                                        value={this.state.first_name}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="last_name">الأسم الأخير:	</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                        required
                                        value={this.state.last_name}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">البريد الإلكتروني:	</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        required
                                        value={this.state.email}
                                        onChange={this.onChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">كلمة المرور: </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        required
                                        value={this.state.password}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-block"><FaUserAlt /> تسجيل البيانات </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Signup;
