import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaArrowCircleRight } from 'react-icons/fa';

class About extends Component {

    state = {};

    async componentDidMount() {}

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <div className="card border-secondary mb-3">
                            <div className="card-header">
                                <Link to={`/`} className="nav-link btn btn-light float-right"><FaArrowCircleRight /></Link>
                                <h4> عن مجلة رسالة الخليج العربي </h4>
                            </div>
                            <div className="card-body">
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left" > اهتمامات المجلة: </h4>
                                    <p className="text-justify">تهتم مجلة رسالة الخليج بنشر الموضوعات التي تعنى بالمجالات التربوية التالية:</p>
                                    <p className="text-justify">- مشكلات الواقع التربوي العربي والإسلامي وقضاياه.</p>
                                    <p className="text-justify">- الفكر التربوي وما يتعلق منه بالمناهج وإعداد المعلمين والادارة المدرسية وسلوك الطلاب والقياس والتقويم والتقنيات الحديثة في مجال التعليم وكافة عناصر العملية التعليمية وسبل تحسين جودتها.</p>
                                    <p className="text-justify">- مستقبل التربية واستشراف آفاقها في المنطقة العربية.</p>
                                    <p className="text-justify">- التجارب التربوية العالمية وفعاليات المؤتمرات والندوات التربوية.</p>
                                </div>
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left" >أعداد المجلة : </h4>
                                    <p className="text-justify">تصدر المجلة بشكل دوري كل ثلاثة أشهر وذلك على النحو الآتي:</p>
                                    <p className="text-justify">- ربيع الأول.</p>
                                    <p className="text-justify">- جماد الثاني.</p>
                                    <p className="text-justify">- رمضان.</p>
                                    <p className="text-justify">- ذو الحجة.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
