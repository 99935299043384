import React, { Component } from 'react';
import img from '../../images/img.jpg';
import Spinner from '../layout/Spinner';
import { FaForward } from 'react-icons/fa';
import { Link } from 'react-router-dom';

class IssueDetails extends Component {

  state = {
    issue: {},
    journalsdetails: null,
  };

  async componentDidMount() {
    let url = `https://apis.abegs.org/abegsjournal/journalsdetails/${this.props.match.params.id}`;
    let data = await fetch(url);
    let json = await data.json();
    this.setState({ issue: json.journal, journalsdetails: json.journalsdetails });
  }
  
  render() {
    const { issue, journalsdetails } = this.state;
    if (issue && journalsdetails) {
      return (
        <div className="container">
          <Link to="/" className="m-1 btn btn-outline-primary"><FaForward />  عودة للقائمة </Link>
          <div className="card">
            <img className="card-img-top" src={img} alt={issue.subject} />
            <div className="card-img-overlay justify-content-center align-items-end">
              <h3 className="card-text text-muted">{issue.issue_number}</h3>
            </div>
            <div className="card-body">
              <h5 className="card-title">{issue.subject}</h5>
              <p className="card-text">السنة : {issue.issue_year}</p>
              <p className="card-text">تاريخ النشر : {issue.issue_date}</p>
            </div>
            <ul className="list-group list-group-flush">
              {this.state.journalsdetails && this.state.journalsdetails.map(journal => (
                <li key={journal.id} className="list-group-item">
                  <p className="text-justify font-weight-bold"> الباحث : {journal.researcher}</p>
                  <p className="text-justify"> البحث : {journal.subject} </p>
                  <Link to={`/research/${journal.id}`} className="btn btn-primary btn-sm float-left">استعراض</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}
export default IssueDetails;
