import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Spinner from "../layout/Spinner";
import { FaArrowCircleRight, FaThList } from 'react-icons/fa';

class Categories extends Component {
    state = {
        categories: [],
    };

    async componentDidMount() {
        let url = "https://apis.abegs.org/abegsjournal/getresearchdomains";
        let data = await fetch(url);
        let json = await data.json();
        this.setState({ categories: json.data });
    }

    render() {
        const { categories } = this.state;
        if (categories.length > 0) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="card w-100">
                            <div className="card-header">
                                <Link to={`/`} className="nav-link btn btn-light float-right"><FaArrowCircleRight /></Link>
                            </div>
                            <div className="card-body w-100">
                                <h5 className="card-title">مجالات البحوث</h5>
                                <p className="card-text">تصنيف البحوث حسب المجالات التربوية </p>
                            </div>
                            <div className="list-group w-100 p-0 m-0">
                                {categories.map(category => (
                                    <Link key={category.id} to={`/categories/${category.id}`} className="list-group-item list-group-item-action w-100">
                                        <div className="d-flex w-100 justify-content-between">
                                            <FaThList />
                                            <h5 className="mb-1">{category.name}</h5>
                                            <span className="badge badge-primary badge-pill">{category.domaincount}</span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}

export default Categories;
