import React, { Component } from 'react';
import Spinner from '../layout/Spinner';
import { Link } from 'react-router-dom';
import { FaForward } from 'react-icons/fa';
import { MobilePDFReader } from 'reactjs-pdf-reader';

class ResearchViewer extends Component {

  state = { issueid: 0, document_url: null, height: '100px' };

  async componentDidMount() {
    let url = `https://apis.abegs.org/journal/journalsdetailsresearchs/${this.props.match.params.id}`;
    let data = await fetch(url);
    let json = await data.json();
    console.log(json);
    if (json.journalsdetail && json.journalsdetail.length > 0) {
      let researchidurl = json.journalsdetail[0].document_url.split("https://library.abegs.org/journal/rpc/download/");
      if (researchidurl.length>1){
        let document_urls = `https://apis.abegs.org/journal/journalsdetailspdf/${researchidurl[1]}`
        this.setState({  issueid: json.journalsdetail[0].journal_issue, document_url: document_urls });
      }
      else{
        this.setState({ researchid: researchidurl[1], issueid: json.journalsdetail[0].journal_issue, document_url: json.journalsdetail[0].document_url });
      }
    }
    this.setState({ height: window.innerHeight + 'px' });
  }

  render() {
    const { document_url, issueid, height } = this.state;
    if (document_url) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="card border-secondary mb-3">
                <div className="card-header"><Link to={`/issue/${issueid}`} className="m-1 btn btn-outline-primary"><FaForward />  عودة للعدد </Link></div>
                <div className="card-body" style={{height:height, display: 'block'}}>
                  <MobilePDFReader url={document_url} isShowHeader={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
  
}
export default ResearchViewer;
