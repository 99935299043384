import React, { Component } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import Spinner from "../layout/Spinner";
import { FaAngleLeft } from 'react-icons/fa';

class Researchs extends Component {
    state = {
        hasMore: true,
        researchs: [],
        start: '',
        search: ''
    };

    async componentDidMount() {
        let url = `https://apis.abegs.org/abegsjournal/getresearchs?start=0`;
        let data = await fetch(url);
        let json = await data.json();
        if (json.paging.moreResults === 'MORE_RESULTS_AFTER_LIMIT') {
            this.setState({ researchs: json.data, paging: json.paging, start: json.paging.endCursor, hasMore: true });
        }
        else {
            this.setState({ researchs: json.data, paging: json.paging, start: json.paging.endCursor, hasMore: false });
        }
    }

    fetchMoreData = async () => {
        if (this.state.hasMore) {
            let url = `https://apis.abegs.org/abegsjournal/getresearchs?start=${this.state.start}`;
            if (this.state.search) {
                url = `https://apis.abegs.org/abegsjournal/getresearchstags?start=${this.state.start}&tags=${this.state.search}`;
            }
            let data = await fetch(url);
            let json = await data.json();
            console.log(json.paging.endCursor);
            if (json.paging.moreResults === 'MORE_RESULTS_AFTER_LIMIT') {
                setTimeout(() => {
                    this.setState(state => ({
                        researchs: [
                            ...state.researchs,
                            ...json.data
                        ],
                        start: json.paging.endCursor,
                        hasMore: true
                    }));
                }, 1500);
            }
            else {
                setTimeout(() => {
                    this.setState(state => ({
                        researchs: [
                            ...state.researchs,
                            ...json.data
                        ],
                        start: '0',
                        hasMore: false
                    }));
                }, 1500);
                return
            }
        }
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSearch = async () => {
        this.setState({ researchs: [] })
        if (this.state.search) {
            let url = `https://apis.abegs.org/abegsjournal/getresearchstags?start=0&tags=${this.state.search}`;
            let data = await fetch(url);
            let json = await data.json();
            if (json.paging.moreResults === 'MORE_RESULTS_AFTER_LIMIT') {
                this.setState({ researchs: json.data, paging: json.paging, start: json.paging.endCursor, hasMore: true });
            }
            else {
                this.setState({ researchs: json.data, paging: json.paging, start: json.paging.endCursor, hasMore: false });
            }
        } else {
            alert("ادخل البحث بالشكل الصحيح")
        }
    }
    render() {
        const { researchs } = this.state;
        if (researchs) {
            return (
                <div className="container">
                    <div className="row bg-light border">
                        <div className="col-12 col-sm-6">
                            <div className="input-group">
                                <input name="search" type="text" className="form-control " placeholder="اكتب الموضوع المراد البحث عنه" onChange={this.onChange} value={this.state.search} />
                                <div className="input-group-prepend">
                                    <div className="btn-group">
                                        <button className="btn btn-secondary " onClick={this.onSearch}>بحث</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <InfiniteScroll dataLength={this.state.researchs.length} next={this.fetchMoreData} hasMore={this.state.hasMore} loader={<Spinner />} endMessage={<p style={{ textAlign: 'center' }}><b>تم عرض كل البيانات</b></p>}>
                        <div className="row">
                            <ul className="list-group p-1 m-1 w-100">
                                {researchs.map((research, index) => (
                                    <li key={index} className="list-group-item">
                                        <p className="mb-1 text-left">{research.subject}</p>
                                        <small className="mb-1 text-left">{research.researcher}</small>
                                        <div className="row">
                                            <Link to={`/issue/${research.issue_number}`} className="m-1 btn btn-outline-primary">نشر في عدد: {research.issue}</Link>
                                            <Link to={`/research/${research.id}`} className="m-1 btn btn-outline-dark">عرض البحث <FaAngleLeft /></Link>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </InfiniteScroll>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}

export default Researchs;
