import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaArrowCircleRight } from 'react-icons/fa';

class Terms extends Component {
    state = {
    };

    async componentDidMount() {

    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <div className="card border-secondary mb-3">
                            <div className="card-header">
                                <Link to={`/`} className="nav-link btn btn-light float-right"><FaArrowCircleRight /></Link>
                                <h4> شروط النشر في المجلة </h4>
                            </div>
                            <div className="card-body">
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left" >لغة النشر</h4>
                                    <p className="text-justify">إن لغة المجلة هي اللغة العربية الفصيحة، مع وجوب التزام الباحثين والمفكرين، بصفة عامة، في صياغتهم اللغوية بمراعاة الوضوح، وسلامة اللغة، وعناصر التشويق، بحيث تجذب القارئ غير المتخصص ، مع ضرورة الالتزام بأخلاقيات البحث العلمي.</p>
                                </div>
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left" >أخلاقيات النشر</h4>
                                    <p className="text-justify">النزاهة: يلتزم الباحثون بأخلاقيات النشر العلمي، ويخضع البحث لكشف السرقة الأدبية. ويرفض كل بحث لا يلتزم بأخلاقيات النشر. ويرفض أي بحث يرسله الباحث لاحقا إذا ثبت إخلاله بهذه الأخلاقيات.</p>
                                    <p className="text-justify">الإقرار: يقر الباحث بأنه لم يسبق نشر البحث، أو عرضه في ندوة أو مؤتمر ، و أنه اطلع على شروط النشر ، وموافق عليها.</p>
                                    <p className="text-justify">حقوق النشر: يوقع الباحث على موافقته على النشر المتاح مجانا (open source) في المجلة.</p>
                                    <p className="text-justify">تضارب المصالح: يتم اعتماد مبدأ عدم تضارب المصالح في المراجعة، لاسيما عن طريق المراجعة العمياء، واختيار مراجعين من خارج المؤسسة، أو من خارج البلد، وذلك بألا يتم اختيار مُراجع على علاقة، أو مصلحة مع الباحث، أو أحد الباحثين، أو المؤسسة، أو الهيئة التي ينتمي إليها الباحث.</p>
                                </div>
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left" >المصادر والمراجع</h4>
                                    <p className="text-justify">تذكر مصادر البحث والمراجع بدقة ووضوح بعد المتن مباشرة، مرقمة بالأرقام العربية، ومرتبة هجائيًا حسب الاسم الأخير للباحث الأول، وفقا لأسلوب نظام جمعية علم النفس الأمريكية (APA)، مع ملاحظة فصل المراجع الأجنبية عن العربية.</p>
                                    <p className="text-justify">عند الاستشهاد بالقرآن الكريم ، يجب ذكر رقم الآية، واسم السورة، وعند الاستشهاد بالحديث النبوي يراعي ذكر السند والدرجة (صحيح - حسن - ضعيف )، مع ذكر المصدر موضحا فيه (بيانات النشر والجزء ورقم الصفحة أو الصفحات والطبعة).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-12">
                        <div className="card border-secondary mb-3">
                            <div className="card-header">الإجراءات والمراحل</div>
                            <div className="card-body">
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left">تقديم البحث</h4>
                                    <p className="text-justify">يتم تقديم البحث إلكترونيا على موقع المجلة <a href="http://library.abegs.org journal" rel="noopener noreferrer" target="_blank" >( أنقر هنا    )</a>
                                    ، بنسختين : نسخة بصيغة Word وأخرى بصيغة PDF ، مع إرفاق السيرة الذاتية في المكان المخصص لها، ويعطى الباحث رمزا يسمح له بتتبع مسار البحث.</p>
                                    <p className="text-justify">ينبغي ألا تزيد كلمات البحث المراد نشره عن 8000 كلمة، أو 30 صفحة من نوع A4، بما فيها الملاحق والجداول والمراجع والبيانات.</p>
                                    <p className="text-justify">تتلقى قاعدة معلومات المجلة وموقعها البحث، وتمنحه رقمه المُعرف به (Digital Identifier)، وتتيح للباحث معرفة مسار بحثه، منذ تقديمه حتى صدور النتيجة النهائية الخاصة به.</p>
                                    <p className="text-justify">يتلقى الباحث إشعارا بالاستلام.</p>
                                    <p></p>
                                </div>
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left">مطابقة الشروط والفحص الأولي:</h4>
                                    <p className="text-justify">يخضع كل بحث لفحص أولي من حيث الشكل (مطابقة الشروط)، ومن حيث المضمون (الفحص الأولي)، تتقرر على أساسه أهليته للدخول في مسار التحكيم، أو يتم رفضه.</p>
                                    <p className="text-justify">تدخل المجلة مرحلة تسمى مرحلة "استكمال الشروط وتحسين البحث"، إذا ما وجدت أن البحث واعد ولكنه بحاجة إلى تحسينات ما قبل التحكيم. وفي هذه المرحلة تقدم للباحث إرشادات، أو توصيات، ترشده إلى سبل هذا التحسين، الذي يساعد على "تأهيل البحث" لمرحلة التحكيم.</p>
                                    <p className="text-justify">يبلّغ الباحث بنتيجة الفحص الأولي: (اعتذار، تعديلات، تحويل للتحكيم) .</p>
                                    <p className="text-justify">عند الإحالة للتحكيم، تعد نسخة من البحث، خالية من اسم الباحث، أو أية إشارة إليه في العنوان والمتن والمراجع .</p>
                                    <p></p>
                                </div>
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left">التحكيم:</h4>
                                    <p className="text-justify">يخضع كل بحث للمراجعة العمياء المزدوجة من قبل الأقران (التحكيم) من ذوي الاختصاص في موضوع البحث.</p>
                                    <p className="text-justify">يرسل البحث إلى محكمين اثنين، ويعد كل منهما ملاحظاته وتقريره حول صلاحيته للنشر والتعديلات المطلوبة، وإذا تضارب التحكيمان في الرأي، فلرئيس التحرير ترجيح أحدهما، أو عرض البحث على مُراجع ثالث.</p>
                                    <p className="text-justify">تحال إلى الباحث تقارير وملاحظات وتعليقات المحكمين، لإجراء التعديلات المطلوبة.</p>
                                    <p className="text-justify">تبقى أسماء المراجعين مغفلة في التقارير ، التي ترسل إلى الباحث.</p>
                                    <p className="text-justify"></p>
                                    <p></p>
                                </div>
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left">إجراءات التعديل:</h4>
                                    <p className="text-justify">يقوم الباحث بإجراء التعديلات اللازمة على البحث، استنادا إلى نتائج التحكيم، ويعيد إرساله إلى المجلة، في مدة أقصاها شهر واحد، مع إظهار هذه التعديلات (track changes).</p>
                                    <p className="text-justify">يبين الباحث في ملف مستقل يرفقه مع البحث المعدل أجوبته عن جميع النقاط، التي أثيرت في رسالة هيئة التحرير، والتقارير التي وضعها المراجعون.</p>
                                    <p className="text-justify">المدة القصوى لإجراء التعديل شهر واحد من تاريخ إخطار الباحث بالتعديل.</p>
                                    <p className="text-justify"></p>
                                    <p className="text-justify"></p>
                                    <p></p>
                                </div>
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left">القبول والرفض:</h4>
                                    <p className="text-justify">تحتفظ المجلة بحق القبول والرفض، استنادا إلى التزام الباحث بقواعد النشر، وبتوجيهات هيئة تحرير المجلة ومحكميها.</p>
                                    <p className="text-justify">يتخذ رئيس التحرير ومساعده القرار النهائي بخصوص قبول البحث، أو رفضه .</p>
                                    <p className="text-justify">تعلم المجلة الباحث النتيجة النهائية (القبول أو الرفض) .</p>
                                    <p className="text-justify"></p>
                                    <p className="text-justify"></p>
                                    <p></p>
                                </div>
                                <div className="p-3 mb-2 bg-light border-right border-info">
                                    <h4 className="text-left">التدقيق والنشر:</h4>
                                    <p className="text-justify">يتلقى المحرر اللغوي البحث المقبول للنشر، ويدقق في سلامة لغته، وخلوه من الأخطاء.</p>
                                    <p className="text-justify">تمنح المجلة الباحث خطاب إفادة بقبول البحث للنشر.</p>
                                    <p className="text-justify">يحال البحث إلى الطباعة والإخراج.</p>
                                    <p className="text-justify">يصدر العدد الخاص من المجلة في موعده المحدد، ويتم إبلاغ الباحث بنشر بحثه فيه.</p>
                                    <p className="text-justify">يرفع ويظهر العدد في قواعد معلومات المجلة وموقعها الذي يوثقها ومن خلال التطبيق</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Terms;
