import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FaArrowCircleRight,
} from "react-icons/fa";
import Spinner from "../layout/Spinner";

class Persons extends Component {
  state = {
    researchs: []
  };

  async componentDidMount() {
    const { isAuthed, userId, token } = this.props;
    if (isAuthed && userId && token) {
      let url = "https://apis.abegs.org/abegsjournal/getuserresearchs";
      let data = await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json', 'authorization': 'Bearer ' + token }});
      let json = await data.json();
      this.setState({ researchs: json.researchs });
    } else {
      this.setState({ token: null, message: "يجب تسجيل الدخول" });
      this.props.history.push("/signin");
    }
  }

  render() {
    const { researchs } = this.state;
    if (researchs) {
      return (
        <div className="container">
          <p>سير العمل </p>
          <div className="row">
            {this.state.researchs && this.state.researchs.length > 0 ? this.state.researchs.map(research => (
              <div className="card border-secondary m-3  mx-auto" style={{ width: "18rem" }} key={research.id}>
                <div className="card-header">{research.process_state}</div>
                <div className="card-body text-secondary">
                  <p className="card-text text-justify">عنوان البحث: {research.subject}</p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <Link to="/" className="col-5 m-1 btn btn-outline-primary btn-sm"><FaArrowCircleRight />  عودة للقائمة </Link>
                  </div>
                </div>
              </div>
            )) : <div>لا يوجد نتائج </div>}
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}
export default Persons;
