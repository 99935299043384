import React, { Component } from 'react';
import Alert from '../layout/Alert';
import jwtDecode from 'jwt-decode'
import { FaUserAlt, FaUserPlus } from 'react-icons/fa';

class Signin extends Component {

  state = {
    email: '',
    password: '',
    message: null,
    messageType: null,
  };

  onSubmit = async e => {
    e.preventDefault();
    try {
      const { email, password } = this.state;
      let url = `https://apis.abegs.org/abegsjournal/signin`;
      let user = { email: email, password: password }
      let data = await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(user) });
      let json = await data.json();
      this.setState({ token: json.accessToken });
      let userId = jwtDecode(this.state.token).userId;
      this.props.parentMethod({ userId, token: json.accessToken, email: this.state.email });
      this.props.history.push('/');
    }
    catch (err) {
      this.setState({ token: null, message: "هناك خطأ في اسم المستخدم أو كلمة المرور ..." });
    }
  }
  onChange = e => this.setState({ [e.target.name]: e.target.value });


  signup = async e => { e.preventDefault(); this.props.history.replace('/signup'); }

  render() {
    return (
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="card">
            <div className="card-body">
              {this.state.message ? (
                <Alert message={this.state.message} messageType="error" />) : null}
              <h4 className="text-center pb-4 pt-3">
                <span className="text-primary">
                  تسجيل الدخول
                </span>
              </h4>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="email">البريد الإلكتروني</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    required
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">كلمة المرور</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    required
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <button type="submit" value="تسجيل الدخول" className="btn btn-primary btn-block"> <FaUserAlt /> تسجيل الدخول </button>
                  <button className="btn btn-success btn-block" onClick={this.signup}> <FaUserPlus /> مستخدم جديد </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
export default Signin;
