import React, { Component } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import Spinner from "../layout/Spinner";
import { FaArrowCircleRight } from 'react-icons/fa';

class CategoryDetials extends Component {
    state = {
        hasMore: true,
        researchs: [],
        start:null
    };

    async componentDidMount() {
        let url = `https://apis.abegs.org/abegsjournal/getresearchdomaindetials?domainid=${this.props.match.params.id}&start=0`;
        let data = await fetch(url);
        let json = await data.json();
        if(json.paging.moreResults === 'MORE_RESULTS_AFTER_LIMIT'){
            this.setState({ researchs: json.data, paging: json.paging, start: json.paging.endCursor, hasMore: true});
        }
        else{
            this.setState({ researchs: json.data, paging: json.paging, start: json.paging.endCursor, hasMore: false});
        }
    }

    fetchMoreData = async () => {
            let url = `https://apis.abegs.org/abegsjournal/getresearchdomaindetials?domainid=${this.props.match.params.id}&start=${this.state.start}`;
            let data = await fetch(url);
            let json = await data.json();
            if(json.paging.moreResults === 'MORE_RESULTS_AFTER_LIMIT'){
                setTimeout(() => {
                    this.setState(state => ({
                        researchs: [
                            ...state.researchs,
                            ...json.data
                        ],
                        start:json.paging.endCursor,
                        hasMore: true
                    }));
                }, 1500);
            }
            else{
                setTimeout(() => {
                    this.setState(state => ({
                        researchs: [
                            ...state.researchs,
                            ...json.data
                        ],
                        hasMore: false
                    }));
                }, 1500);
                return
            }
    };

    render() {
        const { researchs } = this.state;
        if (researchs) {
            return (
                <div className="container">
                    <InfiniteScroll dataLength={this.state.researchs.length} next={this.fetchMoreData} hasMore={this.state.hasMore} loader={<Spinner />} endMessage={<p style={{ textAlign: 'center' }}><b>تم عرض كل البيانات</b></p>}>
                        <div className="row">
                        <div className="card p-1 m-1">
                            <div className="card-header">
                                <Link to={`/`} className="nav-link btn btn-light float-right"><FaArrowCircleRight /></Link>
                            </div>
                            <div className="list-group p-0 m-0">
                                {researchs.map(research => (
                                    <Link to={`/research/${research.id}`} key = {research.id} className="list-group-item list-group-item-action">
                                        <h4 className="mb-1">{research.subject}</h4>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className="mb-1 text-center">{research.researcher}</p>
                                            <span className="float-left badge badge-primary badge-pill">العدد : {research.issue}</span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            </div>
                            </div>
                    </InfiniteScroll>
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}

export default CategoryDetials;
