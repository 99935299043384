import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FaArrowCircleRight } from 'react-icons/fa';

class Domains extends Component {
    state = {};

    async componentDidMount() {

    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <div className="card border-secondary mb-3">
                            <div className="card-header">
                                <Link to={`/`} className="nav-link btn btn-light float-right"><FaArrowCircleRight/></Link>
                                مجالات النشر في المجلة
                            </div>
                            <ul className="list-group list-group-flush text-secondary text-justify">
                                <li className="list-group-item">تركز رسالة الخليج العربي على نشر الدراسات التربوية عالية الجودة وتعميم نتائجها في البيئات التعليمية، لتحقيق التنمية البشرية والمهنية للكوادر المدرسية. تقع اهتمامات سياسة النشر بالمجلة في الفئات التالية.</li>
                                <li className="list-group-item">الأبحاث والدراسات التربوية التي تعالج مشكلات وقضايا الواقع التربوي العربي الاسلامي.</li>
                                <li className="list-group-item">المقالات الفكرية التحليلية الابداعية التي تتناول القضايا الحيوية في مجالات التربية بتنوعها الواسع وعلاقاتها مع العلوم والأخرى والتراث والحياة المعاصرة، دون اعتبار لعدد المراجع والصفحات التي تنضوي عليها.</li>
                                <li className="list-group-item">الحوارات التي تتناول القضايا المتصلة باستراتيجية التربية العربية والاسلامية وسبل التكامل بين النظم التعليمية ومتطلبات التنمية الشاملة للمنطقة.</li>
                                <li className="list-group-item">عرض التجارب التربوية العالمية تحليلياً نقدياً باعتبار المبادئ الاسلامية العربية.</li>
                                <li className="list-group-item">الأبحاث و المقالات المبتكرة لتحديث أساليب تدريس اللغة العربية ولتأكيد أهمية دورها في توحيد الأمة وتعزيز انتمائها لتراثها العريق.</li>
                                <li className="list-group-item">قضايا الترجمة من والى اللغة العربية والاستراتيجيات الخاصة والمنجزات والأساليب الحديثة المتبعة بصددها، وتأكيد أثرها في تعزيز قوة الأمم والدول.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Domains;
